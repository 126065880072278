import { REACT_APP_SKIP_PHONE } from 'envs';
import numeral from 'numeral';

export const NumberWithCommas = (number: number) => {
	return Number(number).toLocaleString();
};

export const useFormatNumber = () => {
	const numberDecimal = (value: number | undefined) => {
		return numeral(value ?? 0).format('0,0.00');
	};

	return { numberDecimal };
};

export const AddZeroInSingleNumbers = (integer: string) => {
	return integer.length === 1 ? `0${integer}` : integer;
};

export const convertToCurrencySystem = (amount: string | number) => {
	return Math.abs(Number(amount)) >= 1.0e9
		? Number((Math.abs(Number(amount)) / 1.0e9).toFixed(2)) + 'B'
		: Math.abs(Number(amount)) >= 1.0e6
		? Number((Math.abs(Number(amount)) / 1.0e6).toFixed(2)) + 'M'
		: Math.abs(Number(amount)) >= 1.0e3
		? Number((Math.abs(Number(amount)) / 1.0e3).toFixed(2)) + 'K'
		: Number(Math.abs(Number(amount)).toFixed(2));
};

export const multiplyNumber = (str1: string, str2: string) => {
	try {
		const num1 = parseFloat(str1);
		const num2 = parseFloat(str2);
		if (isNaN(num1) || isNaN(num2)) {
			return 0;
		}
		return num1 * num2;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
		return 0;
	}
};

export const convertStringToNumber = (value: string) => {
	const num1 = Number(value);
	if (isNaN(num1)) {
		return 0;
	}
	return num1;
};

export const onlyNumber = (val: string) => {
	const numericRegex = /^[0-9]+$/;
	if (numericRegex.test(val)) return true;
	else return false;
};

const convertToBoolean = (
	value: string | undefined | null | boolean
): boolean => {
	return value === 'true';
};
export const isSkipPhoneEnabled = (): boolean => {
	return convertToBoolean(REACT_APP_SKIP_PHONE);
};
import { CreateNewRoleCard } from '../create-new-role-card';
import './no-role-available.scss';

export const NoRoleAvailable = () => {
	return (
		<div className="CreateNewRole--container__body">
			<div className="CreateNewRole--container__body--text">
				<div className="CreateNewRole--container__body--text__header">
					Select role no role
				</div>
				<div className="CreateNewRole--container__body--text__desc">
					To invite user you need to select predefined role or add new role
				</div>
			</div>
			<CreateNewRoleCard />
		</div>
	);
};

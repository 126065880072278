import { Image } from '@storybook';

export const NoData = () => {
	return (
		<div className="kycDetails-noDataFound">
			<Image fileName="missing.svg" />
			<div>No data available</div>
		</div>
	);
};

// another function to go from string to ByteArray, but we first encode the

import { IRegisteredCredentials } from '../type';

// string as base64 - note the use of the atob() function
export const strToBin = (str: string) => {
	return Uint8Array.from(atob(str), c => c.charCodeAt(0));
};

// function to encode raw binary to string, which is subsequently
// encoded to base64 - note the use of the btoa() function

export const binToStr = (bin: any) => {
	return btoa(
		new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), '')
	);
};

export const getRegistrations = () => {
	const registrations = JSON.parse(
		localStorage.simpliciAppRegistration || '[]'
	);
	return registrations;
};

export const setRegistrations = (registrations: IRegisteredCredentials) => {
	localStorage.simpliciAppRegistration = JSON.stringify(
		registrations,
		null,
		'  '
	);
};

export const saveNewCredentials = (registration: IRegisteredCredentials) => {
	const registrations = getRegistrations();
	registrations.push(registration);
	setRegistrations(registrations);
};

export const bufferToBase64URLString = (buffer: any) => {
	const bytes = new Uint8Array(buffer);
	let str = '';

	for (const charCode of bytes as any) {
		str += String.fromCharCode(charCode);
	}

	const base64String = btoa(str);

	return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

const attachments = ['cross-platform', 'platform'];

export const toAuthenticatorAttachment = (attachment: any) => {
	if (!attachment) {
		return;
	}

	if (attachments.indexOf(attachment) < 0) {
		return;
	}

	return attachment;
};

export const base64URLStringToBuffer = (base64URLString: any) => {
	// Convert from Base64URL to Base64
	const base64 = base64URLString.replace(/-/g, '+').replace(/_/g, '/');
	/**
	 * Pad with '=' until it's a multiple of four
	 * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
	 * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
	 * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
	 * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
	 */
	const padLength = (4 - (base64.length % 4)) % 4;
	const padded = base64.padEnd(base64.length + padLength, '=');

	// Convert to a binary string
	const binary = atob(padded);

	// Convert binary string to buffer
	const buffer = new ArrayBuffer(binary.length);
	const bytes = new Uint8Array(buffer);

	for (let i = 0; i < binary.length; i++) {
		bytes[i] = binary.charCodeAt(i);
	}

	return buffer;
};

export const utf8StringToBuffer = (value: any) => {
	return new TextEncoder().encode(value);
};

import { Fragment, useCallback, useEffect, useMemo } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { Button, Loader } from '@storybook';
import {
	ApprovalStatusState,
	LoginCurrentStepState,
	OptionsResponseState,
	isConfirmationModalOpen,
	isWebAuthnSupported,
	useLogin,
	useWebLogin,
	useWebAuthn,
	isWebAuthnRegisterLoader,
	phoneVerifiedWebAuthRegisterToken,
	LoginEmailCredState,
} from 'views';

import { ConfirmationModal } from 'components';
import { Nosupported } from '../no-supported';
import { ScreenLoader } from '../screen-loader';

import './choose-method.scss';
import { isSkipPhoneEnabled } from 'utils';

const params = new URLSearchParams(window.location.search);
const redirectState = params.get('redirectSocialState');

export const ChooseMethod = () => {
	const setLoginCurrentStep = useSetRecoilState(LoginCurrentStepState);

	const optionsResponse = useRecoilValue(OptionsResponseState);
	const verficationStatus = useRecoilValue(ApprovalStatusState);
	const phoneWebAuthRegisterToken = useRecoilValue(
		phoneVerifiedWebAuthRegisterToken
	);
	const [isNotSupportedOpen, setNotSupportedOpen] =
		useRecoilState(isWebAuthnSupported);
	const [isConfirmationOpen, setConfirmationOpen] = useRecoilState(
		isConfirmationModalOpen
	);
	const [isWebLoaded, setIsWebLoaded] = useRecoilState(
		isWebAuthnRegisterLoader
	);
	const resetLoginEmail = useResetRecoilState(LoginEmailCredState);

	const { handleMobileApproval, isApprovalLoading } = useLogin();
	const { registerNewCredential } = useWebAuthn();
	const {
		handleWebAuth,
		handleRegistrastionSuccess,
		startRegisterDevice,
		isWebAuthnCompleted,
		isBiometricLoading,
	} = useWebLogin();

	useEffect(() => {
		if (redirectState) {
			localStorage.setItem('redirectOrigin', redirectState);
		}
	}, []);

	useEffect(() => {
		if (
			(verficationStatus === 'approved' &&
				optionsResponse?.registrationOptions) ||
			(phoneWebAuthRegisterToken && optionsResponse?.registrationOptions)
		) {
			registerNewCredential(
				handleRegistrastionSuccess,
				optionsResponse?.registrationOptions ?? {}
			);
		}
	}, [optionsResponse, phoneWebAuthRegisterToken]);

	const handleGoBack = useCallback(() => {
		const baseUrl = window.location.origin + window.location.pathname;
		history.pushState({}, document.title, baseUrl);
		resetLoginEmail();
		setLoginCurrentStep('CREDS');
	}, [setLoginCurrentStep]);

	const handleConfirmation = useCallback(
		(isOpen: boolean, value: boolean) => {
			setConfirmationOpen(isOpen);
			if (value) {
				setIsWebLoaded(true);
				startRegisterDevice();
			}
		},
		[setConfirmationOpen, startRegisterDevice]
	);

	const chooseMethodArr = useMemo(
		() => [
			{
				category: 'approval',
				componet: (
					<div
						className={`list_item ${
							isApprovalLoading ? 'list_item_disabled' : ''
						}`}
						onClick={() => handleMobileApproval(false)}
					>
						<div className="left_icon">
							<i className="ri-smartphone-line" />
						</div>
						<div className="method_title">Approval via {isSkipPhoneEnabled() ? 'email' : 'text'}</div>
						<div className="right_icon">
							{isApprovalLoading ? (
								<Loader dimension={24} />
							) : (
								<i className="ri-arrow-right-s-line" />
							)}
						</div>
					</div>
				),
			},
			{
				category: 'biometric',
				componet: (
					<div
						className={`list_item ${
							isBiometricLoading ? 'list_item_disabled' : ''
						}`}
						style={{display: 'none'}}
						onClick={handleWebAuth}
					>
						<div className="left_icon">
							<i className="ri-fingerprint-line"></i>
						</div>
						<div className="method_title">Device Biometric</div>
						<div className="right_icon">
							{isBiometricLoading ? (
								<Loader dimension={24} />
							) : (
								<i className="ri-arrow-right-s-line" />
							)}
						</div>
					</div>
				),
			},
		],
		[isApprovalLoading, isBiometricLoading]
	);

	const renderSelectMethod = useMemo(() => {
		return chooseMethodArr.map((item) => (
			<Fragment key={item.category}>{item.componet}</Fragment>
		));
	}, [chooseMethodArr]);

	const renderItem = useMemo(
		() => (
			<div className="wrapper">
				<div className="inner_wrapper">
					<div className="inner">
						<div>
							<div className="title">Choose Method</div>
							<div className="sub_title">
								Select the method through which you want sign in.
							</div>
						</div>

						<div className="lists">{renderSelectMethod}</div>
						<Button
							type="button__filled--secondary button__large button__block"
							label="Back"
							handleClick={handleGoBack}
						/>
					</div>
				</div>
			</div>
		),
		[renderSelectMethod]
	);

	const handalNotSupported = () => {
		setNotSupportedOpen(false);
	};

	return (
		<>
			{renderItem}
			<div className="popup_wrapper">
				{isConfirmationOpen && (
					<ConfirmationModal
						title={'Look like your browser is not registered for webAuthn'}
						visible={isConfirmationOpen}
						description={
							'Please register this browser for WebAuthn before using device biometric login.'
						}
						handleModal={handleConfirmation}
						boldDescription=""
						label={'Register for webAuthn'}
						showLoader={isWebLoaded}
						buttonClass="web-auth-register-button"
						headerIllustration="noData.svg"
					/>
				)}
				<Nosupported
					title={'Look like your browser doesn’t support WebAuthn'}
					visible={isNotSupportedOpen}
					description={'Your browser is does not support WebAuthn.'}
					handleModal={handalNotSupported}
					boldDescription=""
					label={'Close'}
					type="Secondary"
				/>

				<ScreenLoader visible={isWebAuthnCompleted} />
			</div>
		</>
	);
};

import { FC } from 'react';

interface ILatterAvater {
	firstName: string;
	lastName: string;
	optionalClass?: string;
}
export const LatterAvater: FC<ILatterAvater> = ({
	lastName,
	firstName,
	optionalClass,
}) => {
	return (
		<div className={`LatterAvater ${optionalClass}`}>
			{firstName}
			{lastName}
		</div>
	);
};

import { useEffect, useState } from 'react';
import PowerBIReport from './powerBI';
import { Loader } from '@storybook';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';

interface ReportData {
	accessToken: string;
	embedUrl: string;
	reportId: string;
	tokenType: string; // '1' for Embed, '0' for Aad
}

export function Revenue() {
	const [reportData, setReportData] = useState<ReportData | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const { get } = useNetwork();

	// Fetch the embed token and report details
	useEffect(() => {
		const fetchReportData = async () => {
			const response = await get(`${API_URL.REVENUE}?reqType=powerBI`);
			if (response.message === 'ok') {
				// API returns an object with the necessary fields
				const reportDetails: ReportData = {
					accessToken: response.data[0].embedToken,
					embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${response.data[0].reportId}&gr[…]0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
					reportId: response.data[0].reportId,
					tokenType: '1', // For example, '1' for Embed
				};

				setReportData(reportDetails);
				setLoading(false);
			} else {
				setError(response.message);
				setLoading(false);
			}
		};

		fetchReportData();
	}, []);

	if (loading) {
		return <Loader className="loader-blue" dimension={22} />;
	}

	if (error) {
		return <p>Error: {error}</p>;
	}
	return (
		<main className="flex min-h-screen flex-col items-center justify-between p-24">
			{reportData && (
				<PowerBIReport
					accessToken={reportData.accessToken}
					embedUrl={reportData.embedUrl}
					reportId={reportData.reportId}
					tokenType={reportData.tokenType} // Example: '1' for Embed, '0' for Aad
				/>
			)}
		</main>
	);
}
